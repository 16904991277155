import React from 'react'
import { Container, Form, FormGroup, Input } from 'reactstrap'
import styled from 'styled-components'

const Button = styled.button`
  width: 80%;
  background: ${props => props.theme.green};
  border-radius: 0.8em;
  border: 0;
  padding: 0.8em;
  margin: 0 auto;
  color: #fff;
`

export default props => {
  return (
    <Container>
      <Form
        name={props.name}
        method="POST"
        data-netlify="true"
        style={{ maxWidth: '650px', margin: '0 auto' }}
        data-netlify-honeypot="bot-field"
        action="/thanks"
      >
        <input type="hidden" name="form-name" value={props.name} />
        <input type="hidden" name="bot-field" />
        <FormGroup>
          <label>Your name</label>
          <br />
          <Input name="Name" required type="text" />
        </FormGroup>
        <FormGroup>
          <label>Email address</label>
          <br />
          <Input name="Email" required type="email" />
        </FormGroup>
        <FormGroup>
          <label>Phone number</label>
          <br />
          <Input name="Phone Number" required type="tel" />
        </FormGroup>
        <FormGroup>
          <label>Message</label>
          <br />
          <Input name="Message" required type="textarea" />
        </FormGroup>
        <div className="py-3 text-center">
          <Button type="submit">Send</Button>
        </div>
      </Form>
    </Container>
  )
}
