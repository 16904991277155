import React from 'react'
import Layout from '../components/layout'
import { Container } from 'reactstrap'

import Form from '../components/form'

const Contact = () => (
  <Layout>
    <section className="py-5">
      <Container>
        <h1>REQUEST A QUOTE</h1>
        <h2>
          Please leave us a message, and we will contact you to arrange a quote.
        </h2>

        <Form name="contact" />
      </Container>
    </section>
  </Layout>
)

export default Contact
